import React from 'react'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
import './Plans.css'
const Plans = () => {
  return (
    <div className="plans-container" id='plans'>
        <div className="blur plan-blur1"></div>
        <div className="blur plan-blur2"></div>
        <div className="p-header" style={{gap:'2rem'
        }}>
            <span className='stroke-text'>ready to start</span>
            <span>your journey</span>
            <span className='stroke-text'>now with us</span>
        </div>

        {/* planscard */}
        <div className="plans">
                {plansData.map((plan,i)=>(
                        <div className="plan" key={i}>
                            {plan.icon}
                            <span>{plan.name}</span>
                            <span>${plan.price}</span>
                            <div className="features">
                                {plan.features.map((feature,i)=>(
                                    <div className="feature">
                                        <img src={whiteTick} alt="" />
                                        <span key={i}>{feature}</span>
                                    </div>
                                ))}
                            </div>
                            <div ><span>See more benefits &rarr;</span></div>
                            <button className="btn">Join Now</button>
                        </div>
                ))}
        </div>
        


    </div>
  )
}

export default Plans