import React from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
import { useRef } from 'react'
const Join = () => {
  const form=useRef()
  const sendemail=(e)=>{
    e.preventDefault();
    emailjs.sendForm('service_l3di50b', 'template_ojwach7', form.current,'NFH0Z2rdE35anGfdO')
    .then((response)=> {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
  }
  return (
    <div className="join" id="joinus">
      <div className="left-j">
        <hr />
      <div><span  className='stroke-text'>ready to</span>
      <span>level up</span>
      </div>
        
      <div><span>your body</span>
      <span className='stroke-text'>with us ?</span>
      </div>
      </div>

      <div className="right-j">
        <form ref={form} action="" className="email" onSubmit={sendemail}>
          <input type="email" name='user_email' placeholder='enter your email to join'  />
          <button className="btn btn-join">Join Now</button>
        </form>
      </div>

    </div>
  )
}

export default Join