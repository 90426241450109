import React, { useState } from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import {Link} from 'react-scroll'
import Bars from '../../assets/bars.png'
const Header = () => {
  const mobile=window.innerWidth<=768?true:false
  const [menuopen, setmenuopen] = useState(false)
  return (
    <div className="header">
            <img src={Logo} alt="" className='logo'/>
            {(menuopen===false && mobile===true)?(
              <div style={{backgroundColor:'var(--appColor)',padding:'0.5rem',borderRadius:'5px'}}
              onClick={()=>setmenuopen(true)}
              ><img src={Bars} alt="" style={{width:'1.5rem',height:'1.5rem'}} /></div>
            ): 
            <ul className='header-menu'>
            <li ><Link  onClick={()=>setmenuopen(false)} to="home" spy={true} smooth={true} activeClass='active'>Home</Link></li>
            <li ><Link  onClick={()=>setmenuopen(false)} to="programs" spy={true} smooth={true}>Programs</Link></li>
            <li><Link  onClick={()=>setmenuopen(false)} to="reasons" spy={true} smooth={true}>Why us</Link></li>
            <li ><Link  onClick={()=>setmenuopen(false)} to="plans" spy={true} smooth={true}>Plans</Link></li>
            <li ><Link  onClick={()=>setmenuopen(false)} to="testimonials" spy={true} smooth={true}>Testimonials</Link></li>
          </ul>}
            
    </div>
  )
}

export default Header